<template lang="">
    <div>
        My Bots Work
    </div>
</template>
<script>
export default {
    name: 'myBots',
    bodyClass: 'myBots',
    components: {
    },
    data() {
      return {
      }
    }
}
</script>
<style lang="">
    
</style>